import React, { FC } from 'react';

import RouteType from '../../../types/Route';
import DriverProfile from '../../form/Route/Vehicle';

const InfoItem: FC<{ title: string; value?: any }> = ({ title, value }) => (
  <div className="px-5 mt-6 rounded-lg">
    <div className="p-4">
      <p className="uppercase tracking-wide text-sm font-bold text-center text-gray-700 whitespace-nowrap">
        {title}
      </p>
      <p className="text-2xl text-center text-gray-900 whitespace-nowrap">
        {value}
      </p>
    </div>
  </div>
);

const RouteInfos: FC<{ className?: string; item: RouteType }> = ({
  className,
  item,
}) => {
  const { cost, distance, duration, vehicle, volume, weight } = item;
  if (!vehicle) {
    return <p> Véhicle indisponible</p>;
  }

  return (
    <div className={className}>
      <div className="flex justify-center">
        <InfoItem
          title="Distance"
          value={`${Math.round((distance || 0) / 100) / 10}km`}
        />
        <InfoItem
          title="Durée"
          value={`${Math.round((duration || 0) / 60)}'`}
        />
        <InfoItem title="Coût" value={`${(cost || 0) / 100}€`} />
        <InfoItem title="Volume max" value={`${volume || 0}m3`} />
        <InfoItem title="Poids max" value={`${weight || 0}kg`} />
      </div>
      <DriverProfile vehicle={vehicle} />
    </div>
  );
};

export default RouteInfos;

import { DataProps } from 'dataformjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../styles/theme';
import VehicleType from '../../../../types/Vehicle';
import Avatar from '../../../base/Avatar';
import ElementsSpacer from '../../../base/ElementsSpacer';
import Tile from '../../../base/Tile';
import Vehicle from './Vehicle';

interface DriverProps extends DataProps {
  vehicle: VehicleType;
}

const DriverProfile: FC<DriverProps> = ({ vehicle }) => {
  const { t } = useTranslation();

  if (vehicle) {
    const { departurePlace, arrivalPlace, user } = vehicle;

    return (
      <Tile
        className="mb-4"
        header={{
          title: t('driverRecap.driver'),
        }}
      >
        <div className="mb-4">
          <ElementsSpacer>
            <div className="no-print">
              <Avatar />
            </div>
            <div>
              <p style={{ color: colors.neutral.dark }}>
                {user && <strong>{user.name}</strong>}
              </p>

              <p style={{ color: colors.neutral.dark }}>
                {departurePlace?.formattedAddress && (
                  <strong>Départ: {departurePlace?.name}</strong>
                )}
              </p>
              <p style={{ color: colors.neutral.dark }}>
                {arrivalPlace?.formattedAddress && (
                  <strong>Arrivée: {arrivalPlace?.name}</strong>
                )}
              </p>
            </div>
          </ElementsSpacer>
        </div>
        {vehicle && <Vehicle item={vehicle} />}
      </Tile>
    );
  }

  return <p>Conducteur indisponible</p>;
};

export default DriverProfile;

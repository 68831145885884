import { rem } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from '../../../styles/theme';
import IconProfil from '../../icons/Profil';

const AvatarEl = styled.div<{ size: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => rem(props.size)};
  height: ${props => rem(props.size)};
  border-radius: 50%;
  background-size: cover;
`;

interface AvatarProps {
  className?: string;
  size?: number;
}
const Avatar: FC<AvatarProps> = ({ className, size = 48 }) => (
  <AvatarEl className={className} size={String(`${size}px`)}>
    <IconProfil color={colors.light['300']} size={size / 2.25} />
  </AvatarEl>
);

export default Avatar;

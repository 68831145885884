import classnames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { font } from '../../../../styles/theme';
import VehicleType from '../../../../types/Vehicle';
import Title from '../../../base/Title';

const VehicleSC = styled.div`
  @media print {
    > * + * {
      padding-left: 10mm;
      margin-top: 0;
    }
  }
`;

const VehicleItem: FC<{ title: string; value?: any }> = ({ title, value }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-1 text-xs text-neutral-base">{t(title)}</div>
      <Title size={font.size.s[0]}>{value}</Title>
    </div>
  );
};

const Vehicle: FC<{
  item: VehicleType;
  // maxTemperature?: number;
  // showRefrigerated?: boolean;
  // showTemperature?: boolean;
}> = ({ item: { isRefrigerated, hasHatch, hasPalletTruck, name, volume } }) => {
  const { t } = useTranslation();

  return (
    <VehicleSC
      className={classnames(
        ' flex space-x-4',
        'border-t border-neutral-lighter pt-s',
        'print:border-none print:p-0',
      )}
    >
      {name && <VehicleItem title="route.vehicle.name" value={name} />}
      <VehicleItem
        title="route.vehicle.volume"
        value={t('route.vehicle.volume', { count: volume })}
      />

      {isRefrigerated && (
        <VehicleItem
          title="route.vehicle.is-refrigerated"
          value={t(`route.vehicle.has`, {
            context: isRefrigerated ? 'true' : 'false',
          })}
        />
      )}
      {hasHatch && (
        <VehicleItem
          title="route.vehicle.has-hatch"
          value={t(`route.vehicle.has`, {
            context: hasHatch ? 'true' : 'false',
          })}
        />
      )}
      {hasPalletTruck && (
        <VehicleItem
          title="route.vehicle.has-pallet-truck"
          value={t(`route.vehicle.has`, {
            context: hasPalletTruck ? 'true' : 'false',
          })}
        />
      )}
      {/* 

      {showTemperature && (
        <VehicleItem
          title="driverRecap.vehicle.temperature"
          value={
            maxTemperature
              ? t('vehicle.refrigerated.true', { maxTemperature })
              : t('vehicle.refrigerated.ambiant')
          }
        />
      )} */}
    </VehicleSC>
  );
};
export default Vehicle;

import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

// import Button from '../../../../../../components/base/Button';
import CircularProgress from '../../../../../../components/base/CircularProgress';
import GridSplit from '../../../../../../components/base/GridSplit';
import Tabs from '../../../../../../components/base/Tabs';
// import IconFileExport from '../../../../../../components/icons/FileExport';
import RouteActivities from '../../../../../../components/Route/Activities';
import RouteInfos from '../../../../../../components/Route/Infos';
import RouteSteps from '../../../../../../components/Route/Steps';
import AsideBody from '../../../../../../components/Space/Aside/Body';
import planningsRetrieve from '../../../../../../objects/plannings/queries/retrieve';
import PlanningType from '../../../../../../types/Planning';
import RouteType from '../../../../../../types/Route';
import requiredAuth from '../../../../../../utils/requireAuth';

const PageRoutesRead: FC<PageProps> = ({ params: { planningId, routeId } }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(planningsRetrieve, {
    variables: {
      id: planningId,
    },
  });

  if (loading) {
    return <CircularProgress fullPage />;
  }

  const planning: PlanningType =
    data && (data.planningsRetrieve as PlanningType);
  if (!planning) {
    return <div>Aucun planning</div>;
  }

  if (!planning.routes) {
    return <div>Aucune route</div>;
  }

  const route: RouteType | undefined = planning.routes.find(
    r => r.id === routeId,
  );

  if (!route) {
    return <div>La route n&apos;existe pas</div>;
  }

  if (!route.steps || 0 === route.steps.length) {
    return <div>Aucune étape</div>;
  }

  const places = route.steps.map(step => step.place);

  return (
    <GridSplit
      aside={{
        body: <AsideBody places={places} showRoutes />,
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: 'Route',
      }}
    >
      <RouteInfos className="mx-6 mb-6" item={route} />
      <Tabs
        className="mx-6 mb-6"
        values={[
          {
            component: (
              <RouteSteps activities={planning.activities} item={route} />
            ),
            name: 'Étapes',
          },
          {
            component: <RouteActivities item={route} />,
            name: 'Activités',
          },
        ]}
      />
    </GridSplit>
  );
};

export default requiredAuth(PageRoutesRead, {
  admin: true,
  layout: true,
});

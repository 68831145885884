import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ActivityType from '../../../types/Activity';
import RouteType from '../../../types/Route';
import RecapRow from '../../base/RecapRow';
import IconHourGlass from '../../icons/HourGlass';
import IconTruck from '../../icons/Truck';
import IconWatch from '../../icons/Watch';
import Item from '../../new/Item';
import Activity from './Activity';

const RouteSteps: FC<{ activities?: ActivityType[]; item: RouteType }> = ({
  activities,
  item: { steps },
}) => {
  const { t } = useTranslation();

  if (!steps) {
    return null;
  }

  let time = 0;

  return (
    <div className="flex flex-col space-y-12">
      {steps?.map((step, index) => {
        time += step.drivingDuration;
        if (index !== 0) {
          time += steps[index - 1].duration;
        }

        return (
          <Item
            key={objectHash({ stepId: step.id })}
            className="!p-0 !bg-transparent"
            header={{
              className: '',
            }}
            icon={
              <div
                className={classnames(
                  'w-6 h-6 leading-6',
                  'text-light-300 bg-danger-500',
                  'flex items-center justify-center rounded-full',
                )}
              >
                {String.fromCharCode(97 + index).toUpperCase()}
              </div>
            }
            id={step.id}
            subTitle={
              <div className="italic text-light-900">
                {step.place.formattedAddress}
              </div>
            }
            title={step.place.name}
          >
            <ul className="pl-6">
              <RecapRow
                borderBottom
                icon={IconWatch}
                leftChildren={t('waypoint.estimatedTimeForkLabel')}
                rightChildren={`${time / 60} min`}
              />
              {index > 0 && (
                <>
                  <RecapRow
                    borderBottom
                    icon={IconTruck}
                    leftChildren={t('waypoint.drivingDistanceLabel')}
                    rightChildren={`${
                      Math.round(step.drivingDistance / 100) / 10
                    } km`}
                  />
                  <RecapRow
                    borderBottom
                    icon={IconTruck}
                    leftChildren={t('waypoint.drivingDurationLabel')}
                    rightChildren={`${step.drivingDuration / 60} min`}
                  />
                </>
              )}

              <RecapRow
                borderBottom
                icon={IconHourGlass}
                leftChildren={t('waypoint.durationOnSiteLabel')}
                rightChildren={t('waypoint.durationOnSite', {
                  duration: `${step.duration / 60} min`,
                })}
              />
            </ul>
            {step.activities && (
              <div className="flex flex-col space-y-4">
                {step.activities.map(({ id: activityId }) => {
                  const activity = activities?.find(a => a.id === activityId);
                  if (activity) {
                    return (
                      <Activity
                        key={objectHash({
                          activityId,
                          stepId: step.id,
                        })}
                        stepPlaceId={step.place.id}
                        value={activity}
                      />
                    );
                  }

                  return null;
                })}
              </div>
            )}
          </Item>
        );
      })}
    </div>
  );
};

export default RouteSteps;

import React, { FC } from 'react';

import IconProps from '../props';

/* eslint-disable max-len */

const IconProfil: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M8 8.619c3.666 0 6.86 2.119 7.472 5.487C15.645 15.063 15 16 13.956 16H2.043C1 16 .355 15.063.528 14.106 1.14 10.738 4.333 8.619 8 8.619zm0 1.994c-2.522 0-4.656 1.263-5.34 3.234l-.05.158h10.778l-.05-.158c-.66-1.904-2.676-3.15-5.088-3.23L8 10.613zM8 0c2.385 0 4.155 1.914 3.977 4.302C11.81 6.566 10.057 8.458 8 8.458c-2.058 0-3.81-1.894-3.977-4.157C3.845 1.89 5.606 0 8 0zm0 1.994c-1.228 0-2.071.904-1.979 2.163C6.115 5.438 7.065 6.464 8 6.464c.933 0 1.883-1.027 1.978-2.308C10.07 2.919 9.214 1.994 8 1.994z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconProfil;

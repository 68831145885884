import objectHash from 'object-hash';
import React, { FC } from 'react';

import ActivityType from '../../../types/Activity';
import ContainerItem from '../../new/Container/Item';
import ItemSub from '../../new/Item/Sub';

const Activity: FC<{
  value: ActivityType;
  stepPlaceId: string;
}> = ({ stepPlaceId, value }) => {
  const { id, place, user, deliveries } = value;

  // Si l'adresse de l'activité est l'adresse de l'étape
  // cela signifie que l'on est sur un chargement
  if (place.id === stepPlaceId) {
    return (
      <ItemSub
        className="bg-light-500 !mr-0 !ml-6 p-6 rounded"
        title={`Chargement pour ${user?.name}`}
      >
        <div className="flex flex-col space-y-4">
          {deliveries &&
            deliveries.map(delivery => {
              const { containers } = delivery;

              return (
                containers &&
                containers.map(container => (
                  <ContainerItem
                    key={objectHash({
                      activityId: id,
                      containerId: container.id,
                      deliveryId: delivery.id,
                    })}
                    item={container}
                    place={delivery.place}
                    placeType="unloading"
                  />
                ))
              );
            })}
        </div>
      </ItemSub>
    );
  }

  return (
    <ItemSub
      className="bg-light-500 !mr-0 !ml-6 p-6 rounded"
      title={`Déchargement pour ${user?.name}`}
    >
      <div className="flex flex-col space-y-4">
        {deliveries &&
          deliveries.map(delivery => {
            const { containers } = delivery;

            if (delivery.place.id !== stepPlaceId) {
              return null;
            }

            return (
              containers &&
              containers.map(container => (
                <ContainerItem
                  key={objectHash({
                    activityId: id,
                    containerId: container.id,
                    deliveryId: delivery.id,
                  })}
                  item={container}
                  place={place}
                  placeType="loading"
                />
              ))
            );
          })}
      </div>
    </ItemSub>
  );
};

export default Activity;

import { flatten, uniqBy } from 'lodash';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import ActivityType from '../../../types/Activity';
import RouteType from '../../../types/Route';
import Item from '../../new/Item';

const RouteActivities: FC<{ item: RouteType }> = ({ item: { steps } }) => {
  const activities: ActivityType[] | undefined =
    steps &&
    uniqBy(
      flatten(
        steps.map((step: { activities: ActivityType[] }) => step.activities),
      ),
      (activity: ActivityType) => activity.id,
    );

  return (
    <div className="flex flex-col space-y-6">
      {activities?.map(activity => (
        <Item
          key={objectHash({ activityId: activity.id })}
          id={activity.id}
          subTitle={activity.user?.name}
          title={activity.place.name}
        />
      ))}
    </div>
  );
};

export default RouteActivities;
